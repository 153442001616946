.App {
  text-align: center;
}

.betButton {
  background-color: #007bff;
  color: white;
  padding: 12px 24px; /* Adjust size to your preference */
  font-size: 18px; /* Makes text larger */
  border: 2px solid transparent; /* Adds a border that blends into the button, change as needed */
  border-radius: 5px; /* Rounded corners for a modern look */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  transition: background-color 0.2s, transform 0.2s; /* Smooth transition for hover and click */
}

.betButton:hover {
  background-color: #218838; /* Darker shade of green on hover */
}

.betButton:active {
  transform: scale(0.98); /* Slightly shrinks the button when clicked */
}

.bet-component {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.neutral-background {
  background-color: #282c34;
}

.win-background {
  background-color: #003300; /* A soft green */
}

.lose-background {
  background-color: #4C0000; /* A soft red */
}



.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 15px; /* Specified height */
  background: #ddd; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (cross-browser) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1; /* Fully opaque on hover */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular slider handle */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular slider handle */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
